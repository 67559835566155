import React, { Fragment, useRef, useState } from "react";
import _ from "lodash";

import { useCompanyInformation } from "@api/companies";

import { useStyles } from "./styles";

import { useChannelsContext } from "../context";

import { BaseForm } from "~/components/Base/BaseForm";

import Box from "@material-ui/core/Box";

import type { IEditProductAccessFormProps } from "@admin/types";
import { Spinner } from "~/components/Spinner";
import {
	FieldConfigInputProps,
	FieldConfigSelect,
} from "../components/FieldConfigSelect";
import { FieldConfigInput } from "../components/FieldConfigInput";
import ChannelCard from "../components/ChannelCard";
import ProductLabel from "../../../_lib/components/ProductLabel";
import { GetChannelsQuery } from "@api/graphql/types";
import CompanyNameTitle from "~/components/CompanyNameTitle";

const Input = (props: FieldConfigInputProps) => {
	if (props.config.type === "OPTION") {
		return (
			<>
				<FieldConfigSelect {...props} />
			</>
		);
	}

	return (
		<>
			<FieldConfigInput {...props} />
		</>
	);
};

interface Props {
	companyId: number;
	channel: NonNullable<NonNullable<GetChannelsQuery["channels"]>["nodes"]>[0];
}

const Content = ({ companyId, channel }: Props) => {
	if (!channel.companyChannels.nodes?.[0]?.enabled) {
		return null;
	}
	const companyChannelId = channel.companyChannels?.nodes?.[0]?.id;
	return (
		<>
			{channel?.fieldConfigs?.nodes?.map((field) => (
				<Input
					key={field.id}
					companyId={companyId}
					companyChannelId={companyChannelId}
					config={field}
				/>
			))}
		</>
	);
};

export const ChannelsForm: React.FC<IEditProductAccessFormProps> = ({
	companyId,
}) => {
	const styles = useStyles();
	const refs = useRef<HTMLDivElement[]>([]);

	const { channels } = useChannelsContext();
	const { loading: companyLoading, company } = useCompanyInformation(
		Number(companyId),
	);
	const [error, _setError] = useState("");

	return (
		<Fragment>
			<CompanyNameTitle companyName={company?.name || ""} />
			<Box className={styles.root}>
				<Box className={styles.productNav}>
					{channels?.map((channel, index) => {
						return (
							<ProductLabel
								key={channel.name}
								onClick={() =>
									refs?.current[index]?.scrollIntoView({
										behavior: "smooth",
									})
								}
								displayName={channel.displayName}
								productName={"CREDIT_TOOL"}
							/>
						);
					})}
				</Box>
				<Box className={styles.form}>
					{companyLoading && <Spinner className={styles.spinner} />}
					{!companyLoading && (
						<BaseForm error={error} className={styles.formContent}>
							{channels?.map((channel, index) => {
								return (
									<div
										style={{ width: "100%" }}
										ref={(el: HTMLDivElement) =>
											(refs.current[index] = el)
										}
										key={channel.name}
									>
										<ChannelCard
											companyId={companyId}
											channel={channel}
											inputs={
												<Content
													companyId={companyId}
													channel={channel}
												/>
											}
										/>
									</div>
								);
							})}
						</BaseForm>
					)}
				</Box>
			</Box>
		</Fragment>
	);
};
