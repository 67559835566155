import React from "react";

import { createCompanyView, ViewComponent } from "../../_lib/view";
import { ChannelsProvider } from "./_lib/context";

import { ChannelsDisplay } from "./_lib/ChannelsDisplay";

const ChannelsView: ViewComponent = (props) => {
	return (
		<ChannelsProvider>
			<ChannelsDisplay {...props} />
		</ChannelsProvider>
	);
};

export default createCompanyView(ChannelsView, {
	title: "Channels",
	internalPad: true,
});
