import React, { createContext, useContext, useMemo } from "react";
import { useQuery } from "@apollo/client";

import { GetChannels } from "@api/query/channels";
import { GetChannelsQuery, GetChannelsQueryVariables } from "@api/graphql/types";
import { getErrorMessage } from "@utils/errors";
import { useFeedbackContext } from "~/components/Feedback";
import { ChannelFieldInput, useEditCompanyChannels } from "@api/channels";

interface IChannelsContextType {
	channels?: NonNullable<GetChannelsQuery["channels"]>["nodes"];
	loading: boolean;
	createField: (v: ChannelFieldInput) => void;
	updateField: (id: number, v: string) => void;
}

const defaultContext: IChannelsContextType = {
	channels: undefined,
	loading: false,
	createField: (v: ChannelFieldInput) => {
		/* dummy Fn */
	},
	updateField: (id: number, v: string) => {
		/* dummy Fn */
	},
};

const ChannelsContext = createContext<IChannelsContextType>(defaultContext);

export const useChannelsContext = () => {
	const context = useContext(ChannelsContext);
	if (!context) {
		throw new Error(
			"You cannot use the Channels Context outside of its Provider!",
		);
	}
	return context;
};

const useChannelsContextValue = ({ companyId }: { companyId: string }) => {
	const { data, loading } = useQuery<
		GetChannelsQuery,
		GetChannelsQueryVariables
	>(GetChannels, {
		variables: {
			companyId: Number(companyId),
		},
		onError(err) {
			console.error(err);
		},
	});

	const channels = useMemo(() => {
		let arr = [...(data?.channels?.nodes || [])];
		arr.sort((a, b) => {
			if (a.companyChannels.nodes.length) {
				return -1;
			}

			if (b.companyChannels.nodes.length) {
				return 1;
			}

			return 0;
		});

		return arr;
	}, [data]);

	const { handleOpenFeedback } = useFeedbackContext();

	const actions = useEditCompanyChannels();

	const createField = async (v: ChannelFieldInput) => {
		try {
			const result = await actions.createField(v);
			console.log("createField", v, result);
			if (result.errors) {
				throw result.errors;
			}

			handleOpenFeedback({
				message: "Your changes has been saved!",
				severity: "success",
			});
			return result;
		} catch (error) {
			console.error(error);
			handleOpenFeedback({
				message: getErrorMessage(error),
				severity: "error",
			});
		}
		return false;
	};

	const updateField = async (id: number, value: string) => {
		try {
			const result = await actions.updateField(id, value);
			console.log("updateField", id, value, result);
			if (result.errors) {
				throw result.errors;
			}

			handleOpenFeedback({
				message: "Your changes has been saved!",
				severity: "success",
			});
			return result;
		} catch (error) {
			console.error(error);
			handleOpenFeedback({
				message: getErrorMessage(error),
				severity: "error",
			});
		}
		return false;
	};

	return {
		channels,
		loading,
		createField,
		updateField,
	};
};

export const ChannelsProvider: React.FC<any> = (props) => {
	const { children } = props;
	const contextValue = useChannelsContextValue(children?.props?.params);

	return (
		<ChannelsContext.Provider value={contextValue}>
			{children}
		</ChannelsContext.Provider>
	);
};
