import { useMemo } from "react";
import { FetchResult, useMutation } from "@apollo/client";
import { captureError } from "@utils/sentry";

import { CreateField, UpdateField } from "./query/fields";
import { CreateCompanyChannel, UpdateCompanyChannel, GetChannels } from "./query/channels";

import {
	CreateCompanyChannelMutation,
	CreateCompanyChannelMutationVariables,
	CreateFieldMutation,
	CreateFieldMutationVariables,
	UpdateCompanyChannelMutation,
	UpdateCompanyChannelMutationVariables,
	UpdateFieldMutation,
	UpdateFieldMutationVariables,
} from "./graphql/types";
import { makeId } from "@utils/random";
export interface ChannelFieldInput {
	value: string;
	companyId: number;
	companyChannelId: number;
	config: number;
}

export interface ICompanyChannelsActions {
	createField: (input: ChannelFieldInput) => Promise<FetchResult<any>>
	updateField: (id: number, value: string) => Promise<FetchResult<any>>
	createCompanyChannel: (
		companyId: number,
		channelId: number,
	) => Promise<FetchResult<CreateCompanyChannelMutation>>;
	updateCompanyChannel: (
		id: number,
		enabled: boolean,
	) => Promise<FetchResult<UpdateCompanyChannelMutation>>;
}

export const useEditCompanyChannels = (): ICompanyChannelsActions => {
	const [doCreateField] = useMutation<
		CreateFieldMutation,
		CreateFieldMutationVariables
	>(CreateField, {
		refetchQueries: [GetChannels],
		onError: (err) => {
			captureError(err.message, {
				error: err,
			});
		},
	});

	const [doUpdateField] = useMutation<
		UpdateFieldMutation,
		UpdateFieldMutationVariables
	>(UpdateField, {
		refetchQueries: [GetChannels],
		onError: (err) => {
			captureError(err.message, {
				error: err,
			});
		},
	});

	const [doCreateCompanyChannel] = useMutation<
		CreateCompanyChannelMutation,
		CreateCompanyChannelMutationVariables
	>(CreateCompanyChannel, {
		refetchQueries: [GetChannels],
		onError: (err) => {
			captureError(err.message, {
				error: err,
			});
		},
	});

	const [doUpdateCompanyChannel] = useMutation<
		UpdateCompanyChannelMutation,
		UpdateCompanyChannelMutationVariables
	>(UpdateCompanyChannel, {
		refetchQueries: [GetChannels],
		onError: (err) => {
			captureError(err.message, {
				error: err,
			});
		},
	});

	const actions: ICompanyChannelsActions = useMemo(
		() => ({
			createField: (field: ChannelFieldInput) =>
				doCreateField({
					variables: {
						input: { field },
					},
				}),
			updateField: (id: number, value: string) =>
				doUpdateField({
					variables: {
						input: {
							id, patch: { value }
						},
					},
				}),
			createCompanyChannel: (companyId: number, channelId: number) =>
				doCreateCompanyChannel({
					variables: {
						input: {
							companyChannel: {
								widgetId: makeId(12),
								companyId,
								channelId,
							},
						},
					},
				}),
			updateCompanyChannel: (id: number, enabled: boolean) =>
				doUpdateCompanyChannel({
					variables: {
						input: {
							id,
							patch: { enabled }
						},
					},
				}),
		}),
		[doCreateCompanyChannel, doUpdateCompanyChannel],
	);

	return actions;
};
