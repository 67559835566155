import React from "react";

import Box from "@material-ui/core/Box";

import { customStyles } from "./styles";

import type { IChannelCardProps } from "./types";
import { ChannelSwitch } from "../ChannelSwitch";

const ChannelCard = ({
	companyId,
	channel,
	inputs,
}: IChannelCardProps) => {
	const styles = customStyles();
	return (
		<>
			<Box className={styles.content}>
				<ChannelSwitch
					companyId={companyId}
					channel={channel}
				/>
				{!!inputs && <Box className={styles.inputs}>
					{inputs}
				</Box>}
			</Box>
		</>
	);
};

export default ChannelCard;
