import React, { useMemo } from "react";

import { useAuth } from "@api/auth";

import { ViewComponent } from "@settings/view";

import { Redirect } from "~/components/Redirect";

import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

import { ChannelsForm } from "./forms/ChannelsForm";
import { GlobalLoader } from "@settings/GlobalLoader";
import { useChannelsContext } from "./context";
import { useCompanyInformation } from "@api/companies";

const useStyles = makeStyles((theme) => ({
	content: {
		width: "100%",
		backgroundColor: "#F8FAFC",
		padding: 32,
		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			padding: 0,
		},
	},
}));

export const ChannelsDisplay: ViewComponent = (props) => {
	const companyId = Number(props.companyId);
	const { loading } = useChannelsContext();
	const { company } = useCompanyInformation(companyId);
	const { user } = useAuth();
	const styles = useStyles();
	const content = useMemo(() => {
		if (!user?.autocorpAdmin) {
			return <Redirect to="/leads/" />;
		}
		if (loading && !company) {
			return <GlobalLoader />;
		} else {
			return <ChannelsForm companyId={companyId} />;
		}
	}, [company, loading, companyId, user?.autocorpAdmin]);

	return <Box className={styles.content}>{content}</Box>;
};
