import React from "react";

import { useStyles } from "../forms/styles";

import { withStyles } from "@material-ui/core/styles";
import { Typography } from "~/components/Typography";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import { useEditCompanyChannels } from "@api/channels";
import { useFeedbackContext } from "~/components/Feedback";

interface LabelProps {
	title: string;
}

export const LabelWithLogo: React.FC<LabelProps> = ({ title }) => {
	const styles = useStyles();
	return (
		<div className={styles.labelWithLogo}>
			<Typography variant="h4">{title}</Typography>
		</div>
	);
};

export const ChannelSwitch: React.FC<any> = ({ channel, companyId }) => {
	const { handleOpenFeedback } = useFeedbackContext();
	const id = channel?.id;
	const name = channel?.displayName;
	const companyChannel = channel?.companyChannels?.nodes?.[0];
	const enabled = !!companyChannel?.enabled;

	const setError = (message: string) =>
		handleOpenFeedback({ message, severity: "error" });
	const setSuccess = (message: string) =>
		handleOpenFeedback({ message, severity: "success" });

	const styles = useStyles();

	const { updateCompanyChannel, createCompanyChannel } =
		useEditCompanyChannels();

	const toggleTool = async () => {
		try {
			if (companyChannel?.id) {
				const result = await updateCompanyChannel(companyChannel.id, !enabled);
				if ((result.errors as any)?.message) {
					setError((result.errors as any)?.message);
					return;
				}
				if (enabled) {
					handleOpenFeedback({
						message: `${name} has been disabled.`,
						severity: "warning",
					});
				} else {
					setSuccess(`${name} has been enabled.`);
				}
				return !enabled;
			} else {
				const result = await createCompanyChannel(companyId, id);
				if ((result.errors as any)?.message) {
					setError((result.errors as any)?.message);
					return;
				}
				setSuccess(`${name} has been enabled.`);
				return true;
			}
		} catch (error) {
			console.error(error);
		}

		return false;
	};

	const Label = withStyles((theme) => ({
		label: theme.typography.inputLabel,
	}))(FormControlLabel);

	return (
		<Label
			className={styles.toggle}
			key={id}
			control={<Switch checked={enabled} onClick={toggleTool} disabled={!channel?.enabled} />}
			labelPlacement="start"
			label={<LabelWithLogo title={name} />}
		/>
	);
};
