import React, { useCallback, useMemo } from "react";

import { useChannelsContext } from "../context";

import { makeStyles } from "@material-ui/core/styles";

import Select from "~/components/ControlledSelect";

import { GetChannelsQuery } from "@api/graphql/types";
import { SelectOption } from "~/components/ControlledSelect/types";

export const useStyles = makeStyles((theme) => ({
	select: {
		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			display: "none",
		},
	},
}));

export interface FieldConfigInputProps {
	companyId: number;
	companyChannelId: number;
	config: NN<NN<GetChannelsQuery["channels"]>["nodes"]>[0]["fieldConfigs"]["nodes"][0];
}

export const FieldConfigSelect = ({ companyId, companyChannelId, config }: FieldConfigInputProps) => {
	const { createField, updateField } = useChannelsContext();
	const { field, value, options } = useMemo(() => {
		const options = config.options.nodes.map(n => ({ ...n, disabled: !n.enabled }));
		const field = config.fieldsByConfig?.nodes?.[0];
		const value = options.find(option => field?.value === option.value);
		return {
			field,
			value,
			options,
		}
	}, [config]);

	const onSubmit = useCallback(
		async (selected: SelectOption) => {
			if (field?.id) {
				const result = await updateField(field?.id, selected.value);
				console.log("updateField", result);
			} else {
				const result = await createField({ value: selected.value, companyId, config: config.id, companyChannelId });
				console.log("createField", result);
			}
		},
		[value, companyChannelId],
	);

	const styles = useStyles();

	return (
		<Select
			className={styles.select}
			value={value}
			defaultValue={""}
			label={config.label}
			helper={config.helper}
			setValue={onSubmit}
			options={options}
		/>
	);
};
